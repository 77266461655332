import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './PricingPlan.css'; // Importing the CSS
import { loadStripe } from '@stripe/stripe-js';

function PricingPlan({ onSelectPlan}) {
  const navigate = useNavigate();

  const [currentPlan, setCurrentPlan] = useState(null);

  useEffect(() => {
    // Assuming your user id is stored somewhere accessible, like in local storage
    const userId = encodeURIComponent(localStorage.getItem('userId'));
    console.log("Current userId:", userId);
    console.log("Current token:", localStorage.getItem('token'));
    const backendUrl = process.env.REACT_APP_API_BASE_URL
    // Fetch the user's current tier from your backend
    axios.get(`${backendUrl}/user/${userId}/tier`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(response => {
        const tierNumber = response.data.tier;
        
        // Convert tier number to plan name
        const planMapping = {
          0: 'bronze',
          1: 'silver',
          2: 'gold'
        };

        setCurrentPlan(planMapping[tierNumber]);
      })
      .catch(error => {
        console.error('Failed to fetch user tier:', error);
      });
  }, []);

  const handlePlanSelection = async (selectedPlan) => {
    try {
      const backendUrl = process.env.REACT_APP_API_BASE_URL;
      const token = localStorage.getItem('token'); // Retrieve the stored JWT token
      console.log("Current token in handlePlanSelection:", token);

      // Mapping plans to Stripe price IDs
      const priceIds = {
        bronze: 'price_1OIBdgHLCEtxp0765uqaWyvY', // Replace with the actual price ID for the bronze plan in PROD
        silver: process.env.REACT_APP_SILVER_PLAN,
        gold: process.env.REACT_APP_GOLD_PLAN,
      };
      console.log("Selected plan:", selectedPlan);
      console.log("Silver Plan ID:", process.env.REACT_APP_SILVER_PLAN);
      console.log("Gold Plan ID:", process.env.REACT_APP_GOLD_PLAN);
      console.log("PlanId to be sent:", priceIds[selectedPlan]);
      const response = await axios.post(`${backendUrl}/create-or-update-subscription`, {
        planId: priceIds[selectedPlan], // Send the selected plan's price ID to the backend
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
  
      // Redirect to Stripe Checkout with the session ID returned from the backend
      if (response.data.id) {
        const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PK);
        await stripe.redirectToCheckout({ sessionId: response.data.id });
      }
    } catch (error) {
      console.error('Error:', error);
      // Handle errors here, such as displaying a notification to the user
    }
  };

  function getPrice(plan) {
    const prices = {
      bronze: 0,
      silver: 9.99,
      gold: 19.99,
      // Add more plans and prices as needed
    };

    return prices[plan] || 0; // Default to 0 if the plan is not recognized
  }

  function getEmailsPerMonth(plan) {
    const emailCounts = {
      bronze: 2,
      silver: 5,
      gold: 10,
      // Add more plans and prices as needed
    };
    return emailCounts[plan] || 0; // Default to 0 if the plan is not recognized
  }

  return (
    <div className="pricing-container">
      <h2>Your Current Plan: {currentPlan ? currentPlan.toUpperCase() : 'Loading...'}</h2>
      <div className="plan-selection">
      {['bronze', 'silver', 'gold'].map(plan => (
  <div key={plan} className={`plan ${plan} ${plan === currentPlan ? 'current-plan' : ''}`}>
    <h3>{plan.toUpperCase()}</h3>
    <p>${getPrice(plan)} / month</p>
    <p>Up to {getEmailsPerMonth(plan)} active alerts</p>

    <button
      disabled={plan === currentPlan}
      onClick={() => handlePlanSelection(plan)}
    >
      Select {plan}
    </button>
  </div>
))}

      </div>
    </div>
  );
}

export default PricingPlan;