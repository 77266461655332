// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PricingPlan.css */

.pricing-container {
    text-align: center;
    padding: 40px;
  }
  
  .pricing-container h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .plan-selection {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  .plan {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    width: 30%;
    transition: transform 0.3s;
  }
  
  .plan:hover {
    transform: scale(1.05);
  }
  
  .plan h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .plan p {
    margin-bottom: 20px; /* Adjust spacing between price and button */
  }
  
  .plan button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #4CAF50;
    color: #fff;
    transition: background-color 0.3s;
  }
  
  .plan button:hover {
    background-color: #45a049;
  }
  
  .current-plan {
    border: 2px solid #4CAF50; /* Highlight current plan */
  }
  
  .plan button:disabled {
    background-color: #ddd;
    color: #aaa;
    cursor: not-allowed;
  }
  
  .plan button:disabled:hover {
    background-color: #ddd;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/PricingPlan/PricingPlan.css"],"names":[],"mappings":"AAAA,oBAAoB;;AAEpB;IACI,kBAAkB;IAClB,aAAa;EACf;;EAEA;IACE,eAAe;IACf,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;IACzB,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,UAAU;IACV,0BAA0B;EAC5B;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,mBAAmB,EAAE,4CAA4C;EACnE;;EAEA;IACE,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,yBAAyB;IACzB,WAAW;IACX,iCAAiC;EACnC;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB,EAAE,2BAA2B;EACxD;;EAEA;IACE,sBAAsB;IACtB,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,sBAAsB;EACxB","sourcesContent":["/* PricingPlan.css */\n\n.pricing-container {\n    text-align: center;\n    padding: 40px;\n  }\n  \n  .pricing-container h2 {\n    font-size: 24px;\n    margin-bottom: 20px;\n    color: #333;\n  }\n  \n  .plan-selection {\n    display: flex;\n    justify-content: space-around;\n    align-items: center;\n  }\n  \n  .plan {\n    background-color: #f8f8f8;\n    border: 1px solid #ddd;\n    border-radius: 8px;\n    padding: 20px;\n    width: 30%;\n    transition: transform 0.3s;\n  }\n  \n  .plan:hover {\n    transform: scale(1.05);\n  }\n  \n  .plan h3 {\n    font-size: 20px;\n    margin-bottom: 10px;\n  }\n  \n  .plan p {\n    margin-bottom: 20px; /* Adjust spacing between price and button */\n  }\n  \n  .plan button {\n    padding: 10px 20px;\n    font-size: 16px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    background-color: #4CAF50;\n    color: #fff;\n    transition: background-color 0.3s;\n  }\n  \n  .plan button:hover {\n    background-color: #45a049;\n  }\n  \n  .current-plan {\n    border: 2px solid #4CAF50; /* Highlight current plan */\n  }\n  \n  .plan button:disabled {\n    background-color: #ddd;\n    color: #aaa;\n    cursor: not-allowed;\n  }\n  \n  .plan button:disabled:hover {\n    background-color: #ddd;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
