import React from 'react';

function UserProfileDropdown({ handleLogout, username }) {
    return (
        <div className="dropdown">
            <span className="user-icon">User</span>
            <div className="username">{username}</div>
            <div className="dropdown-content">
                <a href="#!" onClick={handleLogout}>Logout</a>
                {/* Add other links like Profile and Settings here if needed in the future */}
            </div>
        </div>
    );
}

export default UserProfileDropdown;
