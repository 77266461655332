import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './StockAlertManagement.css';

function StockAlertManagement({ handleLogout }) {
  const [alerts, setAlerts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAlerts = async () => {
      const headers = {
        'Authorization': `Bearer ${token}`
      };

      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/user/alerts`, { headers });
        console.log('Fetched alerts:', response.data.alerts); // Log the fetched alerts
        setAlerts(response.data.alerts);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching alerts:', err);
        setError(err.message || 'An error occurred');
      }
    };

    fetchAlerts();
  }, [token]); // Removed navigate from the dependency array as it's not used inside the useEffect

  const handleEditAlert = (alertId) => {
    // Placeholder for edit functionality
    console.log("Editing alert:", alertId);
  };

  const handleDeleteAlert = async (alertId) => {
    const headers = {
      'Authorization': `Bearer ${token}`
    };

    try {
      await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/user/alerts/${alertId}`, { headers });
      setAlerts(prevAlerts => prevAlerts.filter(alert => alert.id !== alertId));
    } catch (err) {
      console.error('Error deleting the alert:', err);
    }
  };

  const renderCriteria = (criteriaString) => {
    console.log('Received criteria string:', criteriaString); // Log the raw criteria string
  
    const percentageFields = ['Net Profit Margin TTM', 'Revenue Growth QoQ', 'Gross Margin TTM', 'EPS Growth QoQ',
  '5 Day Return', '26 Week Return', '13 Week Return', '52 Week Return', 'YTD Return'];
  
    return criteriaString.split(', ').map((criterion, index) => {
      const parts = criterion.split(' ');
      const valueString = parts.pop(); // Get the last element as the value
      const operator = parts.pop(); // Get the second last element as the operator
      const field = parts.join(' '); // Join the remaining parts back as the field
  
      console.log(`Before conversion, type of value: ${typeof valueString}, value: '${valueString}'`); // Log the type and value
  
      let value = parseFloat(valueString);
  
      if (!isNaN(value)) {
        value = (value % 1 === 0) ? Math.round(value).toString() : value.toFixed(2);
        // Check if the field should have a percentage sign
        if (percentageFields.includes(field)) {
          value += '%';
        }
      } else {
        // Handle the case where value is not a number
        value = 'Invalid value'; // or some other placeholder to indicate the issue
      }
  
      const formattedCriterion = `${field} ${operator} ${value}`;
      return (
        <span key={index} className="criteria-badge">{formattedCriterion}</span>
      );
    }).filter(criterion => criterion !== null); // Filter out invalid values
  };
  
  

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="Alerts">
      <h1>Your Stock Alerts</h1>
      <div className="tableContainer">
        <table>
          <thead>
            <tr>
              <th>Description</th> {/* Changed from Id to Description */}
              <th>Criteria</th>
              <th>Frequency</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {alerts.map(alert => (
              <tr key={alert.id}> {/* Keep using alert.id as key */}
                <td>{alert.description}</td> {/* Display alert.description */}
                <td>{renderCriteria(alert.filter_string)}</td>
                <td>{alert.frequency}</td>
                <td>
                  <button onClick={() => handleDeleteAlert(alert.id)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button onClick={() => navigate('/stock-criteria')}>
        Add New Alert
      </button>
    </div>
  );
}

export default StockAlertManagement;