// StockCriteria.js
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import './StockCriteria.css';

const StockCriteria = ({ 
    netProfitMargin, setNetProfitMargin,
    marketCapMin, setMarketCapMin,
    marketCapMax, setMarketCapMax,
    revenueGrowthQuarterlyYoy, setGrowthRate,
    grossMargin, setGrossMargin,
    alertFrequency, setAlertFrequency,
    psRatioMin, setPsRatioMin,
    psRatioMax, setPsRatioMax,
    epsGrowthQuarterlyYoy, setEpsGrowthRate,
    handleLogout, 
    token   // Receive token from parent
}) => {
    const navigate = useNavigate();
    const [submitStatus, setSubmitStatus] = useState(""); // to show messages to the user
    const [sendEmailAnyways, setSendEmailAnyways] = useState(false); // State to manage the checkbox
    const [description, setDescription] = useState(""); // State to manage the description text
    const [timeFrame, setTimeFrame] = useState('5 Day Return');
    const [operator, setOperator] = useState('>');
    const [threshold, setThreshold] = useState('');

    const backendUrl = process.env.REACT_APP_API_BASE_URL;
    console.log("backendUrl is here :", backendUrl)
    console.log('Stored userId:', localStorage.getItem('userId'));

    const handleSubmit = async (e) => {
        e.preventDefault();

        const stockFilters = {
            marketCapMin,
            marketCapMax,
            revenueGrowthQuarterlyYoy,
            epsGrowthQuarterlyYoy,
            grossMargin,
            netProfitMargin,
            psRatioMax,
            psRatioMin,
            alertFrequency,
            timeFrameFilter: {
              timeFrame,
              operator,
              threshold: threshold === '' ? null : Number(threshold)
            },
            description,
            sendEmailAnyways
        };

        const headers = {};
        if (token) {
            headers['Authorization'] = `Bearer ${token}`; // Attach token if available
        }

        try {
            console.log("about to log if there is a token or not")
            console.log(token);
            const response = await axios.post(`${backendUrl}/filters`, stockFilters, { headers });
            console.log('Data sent successfully:', response.data);
            setSubmitStatus("Data sent successfully!");
            setTimeout(() => {
            navigate('/alerts'); // redirecting to Stock Alert Management page after 2 seconds
        }, 2000);
    } catch (error) {
        console.error('Error sending data:', error);

        // Check if the error response from the backend has a message
        let errorMessage = error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : "Error sending data. Please try again.";
        setSubmitStatus(errorMessage);
    }
    };
    return (
      <div className="App">
        <h1>Make a New Alert</h1>
        <p style={{ color: '#888', fontSize: '0.9em' }}>Leave any field blank to ignore its criteria.</p>
        <form onSubmit={handleSubmit} className="form-container">
          <div className="left-group">
            <div className="market-cap-container">
              <div>
                <label>Market Cap Min (in millions $):</label>
                <input
                  className="small-input"
                  type="number"
                  value={marketCapMin}
                  onChange={e => setMarketCapMin(e.target.value)}
                  placeholder="E.g. 1000"
                />
              </div>
            </div>
            <div className="growth-rates-container">
              <div>
                <label>Min Revenue Growth Rate (%):</label>
                <input
                  className="smaller-input"
                  type="number"
                  value={revenueGrowthQuarterlyYoy}
                  onChange={e => setGrowthRate(e.target.value)}
                  placeholder="E.g. 15"
                />
              </div>
            </div>
            <div className="margins-container">
              <div>
                <label>Min Gross Margin (%) :</label>
                <input
                  type="number"
                  value={grossMargin}
                  onChange={e => setGrossMargin(e.target.value)}
                  placeholder="E.g. 20"
                />
              </div>
            </div>
            <div className="ps-ratio-container">
              <div>
                <label>P/S Ratio Min:</label>
                <input
                  className="small-input"
                  type="number"
                  value={psRatioMin}
                  onChange={e => setPsRatioMin(e.target.value)}
                  placeholder="E.g. 1"
                />
              </div>
            </div>
            <div className="frequency-selection">
              <label>Alert Frequency: </label>
              <select value={alertFrequency} onChange={(e) => setAlertFrequency(e.target.value)}>
                <option value="Daily">Daily</option>
                <option value="Weekly">Weekly</option>
                <option value="Monthly">Monthly</option>
                <option value="New">When a new stock qualifies (not to exceed daily)</option>
              </select>

              </div>
              <div className="input-group checkbox-container">
                <input
                  type="checkbox"
                  id="sendEmailAnywayCheckbox"
                  checked={sendEmailAnyways}
                  onChange={e => setSendEmailAnyways(e.target.checked)}
                />
                <label htmlFor="sendEmailAnywayCheckbox">Send alert if no stocks qualify</label>
              </div>

          </div>

          <div className="right-group">
            <div className="market-cap-container">
              <div>
                <label>Market Cap Max (in millions $):</label>
                <input
                  className="small-input"
                  type="number"
                  value={marketCapMax}
                  onChange={e => setMarketCapMax(e.target.value)}
                  placeholder="E.g. 10000"
                />
              </div>
            </div>
            <div className="growth-rates-container">
              <div>
                <label>Min EPS Growth Rate (%):</label>
                <input
                  className="smaller-input"
                  type="number"
                  value={epsGrowthQuarterlyYoy}
                  onChange={e => setEpsGrowthRate(e.target.value)}
                  placeholder="E.g. 10"
                />
              </div>
            </div>
            <div className="margins-container">
              <div>
                <label>Min Net Profit Margin (%) :</label>
                <input
                  type="number"
                  value={netProfitMargin}
                  onChange={e => setNetProfitMargin(e.target.value)}
                  placeholder="E.g. 10"
                />
              </div>
            </div>
            <div className="ps-ratio-container">
              <div>
                <label>P/S Ratio Max:</label>
                <input
                  className="small-input"
                  type="number"
                  value={psRatioMax}
                  onChange={e => setPsRatioMax(e.target.value)}
                  placeholder="E.g. 10"
                />
              </div>
            </div>
            <div className="input-group">
                <label htmlFor="description">Description:</label>
                <input
                    type="text"
                    id="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Enter a custom description"
                />
            </div>
            <div className="input-group">
              <label>Return per Time Period:</label>
              <div className="return-time-period-container">
                <select className="time-frame-select" value={timeFrame} onChange={(e) => setTimeFrame(e.target.value)}>
                  <option value="5 Day Return">5D</option>
                  <option value="13 Week Return">13W</option>
                  <option value="26 Week Return">26W</option>
                  <option value="52 Week Return">52W</option>
                  <option value="YTD Return">YTD</option>
                </select>
                <select className="operator-select" value={operator} onChange={(e) => setOperator(e.target.value)}>
                  <option value=">">&gt;</option>
                  <option value="<">&lt;</option>
                </select>
                <input className="threshold-input" type="number" value={threshold} onChange={(e) => setThreshold(e.target.value)} placeholder="E.g. 30" />
              </div>
            </div>

          </div>
        </form>
        <button type="submit" onClick={handleSubmit} className="submit-btn">Submit</button>
        <p>{submitStatus}</p>

      </div>
    );
}

export default StockCriteria;
