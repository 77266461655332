import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using react-router-dom for routing

function CheckoutCompletion() {
  return (
    <div className="checkout-completion">
      <h2>Thank you for your purchase!</h2>
      <p>Your order has been processed successfully.</p>
      <Link to="/alerts">Return to Alerts</Link>
    </div>
  );
}

export default CheckoutCompletion;
