import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

const LoginForm = ({ onLogin }) => {
    const navigate = useNavigate();

    console.log("LoginForm is rendering");
    const location = useLocation();

    const backendUrl = process.env.REACT_APP_API_BASE_URL
    
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loginError, setLoginError] = useState("");
    const [registering, setRegistering] = useState(false);
    const [registrationConfirmation, setRegistrationConfirmation] = useState("");
    const [registeredEmail, setRegisteredEmail] = useState("");


    console.log("backendUrl is here :", backendUrl)

    useEffect(() => {
      console.log("Email state changed:", email);
    }, [email]);
  
    useEffect(() => {
      console.log("Password state changed:", password);
    }, [password]);

    useEffect(() => {
        // The ping request logic when component mounts
        console.log("about to ping the server");

        const pingServer = async () => {
            try {
              console.log("trying to ping the server");

                const pingResponse = await axios.get(`${backendUrl}/ping`);
                if (pingResponse.data.success) {
                    console.log("Ping successful");
                } else {
                    console.log("Ping failed");
                }
            } catch (pingError) {
                console.error('Error during ping:', pingError);
            }
        };
        
        pingServer(); // Call the ping function
    }, []); // Empty dependency array ensures this useEffect runs only once when component mounts

    const handleLogin = async (e) => {
        console.log("handleLogin called");
        e.preventDefault();
    
        try {
            const loginResponse = await axios.post(`${backendUrl}/login`, { email, password });
            if (loginResponse.data.success) {
                const { token, userId, username } = loginResponse.data;
                localStorage.setItem('token', token);
                localStorage.setItem('userId', userId);
                localStorage.setItem('username', username);
    
                onLogin(token);
                setLoginError("");
                navigate('/stock-criteria'); // Add this line for navigation after login
            } else {
                setLoginError(loginResponse.data.message);
            }
        } catch (error) {
            setLoginError("An unexpected error occurred while logging in. Please try again.");
            console.error('Error during login:', error);
        }
    };

    const handleResendVerificationEmail = async () => {
        try {
            const response = await axios.post(`${backendUrl}/resend-verification`, { email: registeredEmail });
            if (response.data.success) {
                setRegistrationConfirmation("Verification email resent! Please check your inbox.");
            } else {
                setLoginError(response.data.message);
            }
        } catch (error) {
            setLoginError("An error occurred while resending the verification email. Please try again.");
            console.error('Error during resend verification:', error);
        }
    };
    
    
    
  
    const handleRegistration = async (e) => {
        console.log("handleRegistration called");
        e.preventDefault();
    
        try {
            console.log("Attempting to register with email:", email, "and password:", password);
            const response = await axios.post(`${backendUrl}/register`, { email, password });
            if (response.data.success) {
                setRegisteredEmail(email); // Save the registered email
                setRegistrationConfirmation("Registration successful! Please check your email to verify your account.");
                setEmail("");
                setPassword("");
                setLoginError("");
            } else {
                setLoginError(response.data.message);
            }
        } catch (error) {
            setLoginError("An unexpected error occurred while registering. Please try again.");
            console.error('Error during registration:', error);
        }
    };
    

    return (
        <div className="App">
            {registering ? <h1>Register</h1> : <h1>Login</h1>}
            {!registrationConfirmation && (
                <form onSubmit={registering ? handleRegistration : handleLogin}>
                    <div>
                        <label>Email:</label>
                        <input
                            className="login-form-input" // Apply the new class to this input
                            type="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>Password:</label>
                        <input
                            className="login-form-input" // And apply the new class to this input as well
                            type="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    {loginError && <p style={{color: 'red'}}>{loginError}</p>}
                    <button type="submit">{registering ? "Register" : "Login"}</button>
                </form>
            )}
            {registrationConfirmation && (
                <div>
                    <p style={{ color: 'green' }}>{registrationConfirmation}</p>
                    {registering && (
                        <button onClick={() => handleResendVerificationEmail(email)}>Resend Verification Email</button>
                    )}
                </div>
            )}
            {!registrationConfirmation && registering && (
                <p>
                    Already have an account?
                    <button onClick={() => setRegistering(false)}>Login here</button>
                </p>
            )}
            {!registrationConfirmation && !registering && (
                <p>
                    Don't have an account?
                    <button onClick={() => setRegistering(true)}>Register here</button>
                </p>
            )}
        </div>
    );
}

export default LoginForm;
