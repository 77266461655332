import React, { useState, useEffect } from 'react';
import './App.css';
import axios from 'axios';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import LoginForm from './components/LoginForm';
import StockAlertManagement from './components/StockAlertManagement/StockAlertManagement';
import StockCriteria from './components/StockCriteria';
import { useNavigate, useLocation } from 'react-router-dom';
import CheckoutCompletion from './components/CheckoutCompletion/CheckoutCompletion';
import PricingPlan from './components/PricingPlan/PricingPlan';
import UserProfileDropdown from './components/UserProfileDropdown/UserProfileDropdown';

function AppContent({ appConfig }) {
  const navigate = useNavigate();
  const backendUrl = appConfig.backendUrl; // Extract backendUrl from appConfig

  const [loggedIn, setLoggedIn] = useState(Boolean(localStorage.getItem('token')));
  const username = localStorage.getItem('username'); // Fetch 'username' from local storage

  // Stock Criteria states
  const [netProfitMargin, setNetProfitMargin] = useState('');
  const [marketCapMin, setMarketCapMin] = useState('');
  const [marketCapMax, setMarketCapMax] = useState('');
  const [revenueGrowthQuarterlyYoy, setGrowthRate] = useState('');
  const [grossMargin, setGrossMargin] = useState('');
  const [psRatioMin, setPsRatioMin] = useState('');
  const [psRatioMax, setPsRatioMax] = useState('');
  const [epsGrowthQuarterlyYoy, setEpsGrowthRate] = useState('');
  const [alertFrequency, setAlertFrequency] = useState('Daily');
  const location = useLocation();

  const handleLogout = () => {
    localStorage.removeItem('token');  
    setLoggedIn(false);
    navigate('/login'); // Assuming your root route (`/`) is where the login form is
  };
  
  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          handleLogout();
        }
        return Promise.reject(error);
      }
    );
  
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [handleLogout]);

  const handleSubmit = () => {
    // You can define logic here that should run when criteria are submitted
  };

  const onLogin = (token) => {
    setLoggedIn(true, () => {
        navigate('/stock-criteria');
    });
  };
  
  if (!loggedIn) {
    return <LoginForm onLogin={() => setLoggedIn(true)} />;
  }

  return (
    <div>
      <nav>
      <Link to="/alerts" className={location.pathname === "/alerts" ? "active" : ""}>Alerts</Link>
      <Link to="/pricing-plan" className={location.pathname === "/pricing-plan" ? "active" : ""}>Pricing Plan</Link>
      <UserProfileDropdown handleLogout={handleLogout} />
      </nav>

        <Routes>
          <Route 
            path="/stock-criteria" 
            element={
              <StockCriteria
                  token={localStorage.getItem('token')}
                    netProfitMargin={netProfitMargin}
                    setNetProfitMargin={setNetProfitMargin}
                    marketCapMin={marketCapMin}
                    setMarketCapMin={setMarketCapMin}
                    marketCapMax={marketCapMax}
                    setMarketCapMax={setMarketCapMax}
                    revenueGrowthQuarterlyYoy={revenueGrowthQuarterlyYoy}
                    setGrowthRate={setGrowthRate}
                    grossMargin={grossMargin}
                    setGrossMargin={setGrossMargin}
                    psRatioMin={psRatioMin}
                    setPsRatioMin={setPsRatioMin}
                    psRatioMax={psRatioMax}
                    setPsRatioMax={setPsRatioMax}
                    epsGrowthQuarterlyYoy={epsGrowthQuarterlyYoy}
                    setEpsGrowthRate={setEpsGrowthRate}
                    alertFrequency={alertFrequency}
                    setAlertFrequency={setAlertFrequency}
                    handleSubmit={handleSubmit}
                    handleLogout={handleLogout}
                  />
            } 
          />

        <Route path="/alerts" element={<StockAlertManagement handleLogout={handleLogout} />} />
        <Route path="/login" element={<LoginForm onLogin={() => setLoggedIn(true)} />} />
        <Route path="/checkout-completion" element={<CheckoutCompletion />} />
        <Route path="/pricing-plan" element={<PricingPlan />} />
        <Route path="/" element={<Navigate to="/alerts" replace />} />

      </Routes>
    </div>
  );
}

function App() {
  const [appConfig, setAppConfig] = useState({});
  const [configLoaded, setConfigLoaded] = useState(false);

  useEffect(() => {
    fetch('/config.json')
      .then(response => response.json())
      .then(config => {
        setAppConfig(config);
        setConfigLoaded(true);
      })
      .catch(error => {
        console.error('Error loading configuration:', error);
        // Handle the error as needed.
      });
  }, []);

  if (!configLoaded) {
    return <div>Loading...</div>;
  }
  return (
    <Router>
      <AppContent appConfig={appConfig} />
    </Router>
  );
}

export default App;